<template>
    <v-card v-if="$route.meta.fullpage !== true">
      <v-navigation-drawer
        app
        persistent
        mobile-breakpoint="991"
        v-model="inputValue"
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <!-- <v-img src="@/assets/img/logo.jpeg" width="16"></v-img> -->
              <v-img
                src="@/assets/img/logo.jpeg"
                width="100%"
              />
            </v-list-item-avatar>
            <h2>Lar Ismael</h2>
          </v-list-item>
          <v-divider/>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ currentUser ? currentUser.name : "" }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ currentUser ? currentUser.email : '' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
          nav
          dense
        >
          <v-list-item link to="/admin/maes">
            <v-list-item-icon>
              <v-icon>mdi-mother-heart</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mães</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/admin/criancas">
            <v-list-item-icon>
              <v-icon>mdi-human-female-boy</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Crianças</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/admin/cestabasica">
            <v-list-item-icon>
              <v-icon>mdi-basket-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Centas Básicas</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Listas</v-list-item-title>
          </v-list-item> -->
          <v-list-group
            :value="false"
            prepend-icon="mdi-hammer-screwdriver"
          >
            <template v-slot:activator>
              <v-list-item-title>Configurações</v-list-item-title>
            </template>

            <v-list-item link to="/admin/configuracao/inscricao">
              <v-list-item-icon>
                <v-icon>mdi-application-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Incrições</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/admin/colaboradores">
              <v-list-item-icon>
                <v-icon>mdi-account-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Colaboradores</v-list-item-title>
            </v-list-item>
            
          </v-list-group>
          <!-- <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-hammer-screwdriver</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Configurações</v-list-item-title>
          </v-list-item> -->
          
          <!-- <v-list-item link active-class="primary" class="v-list-item v-list__tile--buy" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-exit-run</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item> -->
        </v-list>
        <!-- <template v-slot:append>
          <div class="pa-2">
            <v-btn text @click="logout" class="pl-0">
              <v-icon>mdi-exit-run</v-icon> Sair
            </v-btn>
          </div>
        </template> -->
      </v-navigation-drawer>
    </v-card>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState(['currentUser']),
    inputValue: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.setDrawer(val)
      }
    },
  },
  methods: {
    ...mapActions(['logout']),
    ...mapMutations(["setDrawer", "toggleDrawer"]),
  }
}
</script>